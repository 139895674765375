.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.innerContainer {
  position: relative;
  height: calc(100vh - 80px);
}
