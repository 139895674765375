.root {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  /* opacity: 0.94; */
  background-image: linear-gradient(to bottom, #0c0c11, rgba(12, 12, 17, 0.97));
  display: flex;
  flex: 1;
  z-index: 30;
}

.sidePanel {
  flex: 0.2;
  background: rgba(0, 0, 0, 0.1);
  background-color: #1f2027;
  overflow-y: auto;
  min-width: 300px;
  padding-bottom: 40px;
}

.mainPanel {
  flex: 0.8;
  padding-bottom: 112px;
}

.closeButtonContainer {
  right: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  pointer-events: none;
}
