.root {
  /* top: 0; */
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: all;
}

.topRow {
  display: flex;
  justify-content: center;
}

.liveButton {
  padding: 8px;
  padding-right: 16px;
  padding-left: 10px;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.6);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.liveButton:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.8);
}

.liveButton img {
  width: 32px;
  height: 32px;
  margin-right: 18px;
}

.liveButton:hover,
:active,
:focus {
  outline: 0;
}

.liveButton span {
  font-size: 18px;
  /* font-weight: bold; */
  color: white;
}

.bottomRow {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 1);
  flex: 3;
  padding-left: 40px;
  padding-right: 40px;
}

.menuIcon {
  border: none;
  background: transparent;
  border-radius: 40px;
  padding: 20px;
  margin: 5px;
}

.icon {
  vertical-align: middle;
  height: 24px;
  width: 24px;
}

.menuIcon:hover {
  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
}

.menuIcon:hover,
:active,
:focus {
  outline: 0;
}

.column {
  flex: 1;
  display: flex;
}

.pausedMessage p {
  width: calc((100vw - 140px) / 3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 20px;
}

.pausedMessage span:first-of-type {
  font-size: 18px;
  font-weight: 900;
}

.pausedMessage span:nth-of-type(2) {
  font-size: 18px;
  font-weight: normal;
  color: #fffdfd;
  margin-left: 16px;
}

.liveMessage {
  display: flex;
  align-items: center;
}

.liveMessage p {
  width: calc((100vw - 140px) / 3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 20px;
}

.liveMessage div {
  width: 14px;
  height: 14px;
  background-color: #68ff2f;
  border-radius: 50%;
  margin-right: 6px;
}

.liveMessage span:first-of-type {
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  color: #68ff2f;
}

.liveMessage span:nth-of-type(2) {
  font-size: 18px;
  font-weight: normal;
  color: #fffdfd;
  margin-left: 16px;
}

.fullscreenButton {
  border: 0;
  background: transparent;
  margin-right: -20px;
  height: 36px;
  width: 36px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreenButton:hover {
  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
}

.fullscreenButton img {
  height: 18px;
  width: 18px;
}

.fullscreenButton:hover,
:active,
:focus {
  outline: 0;
}

.disabledMenuButton {
  opacity: 0.33;
  border: none;
  background: transparent;
  border-radius: 40px;
  padding: 10px;
  margin: 5px;
}
