.root {
  padding: 0px 40px 40px 40px;
  height: 100%;
}

.grid {
  padding: 100px 50px 50px 50px;
  display: flex;
  height: 100%;
  flex-flow: wrap;
  overflow-y: auto;
  max-height: 80%;
  align-content: flex-start;
}

.gridItem {
  justify-content: center;
  display: flex;
  border-radius: 10px;
  border: 1px solid transparent;
  flex-direction: column;
  padding: 16px;
  margin: 0 20px;
  width: fit-content;
  cursor: pointer;
  height: fit-content;
  border: solid 2px transparent;
  width: 204px;
  height: 166px;
}

.gridItem:hover {
  background: rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.selectedGridItem {
  border: solid 2px rgba(255, 255, 255, 0.5);
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.itemIcon {
  background-color: #ccc;
  border-radius: 10px;
  height: 114px;
  width: 204px;
  overflow: hidden;
}

.itemIcon:hover {
  transform: scale(1.06);
  transition: transform 0.3s ease-out 0s;
}

.itemIcon img {
  width: 100%;
}

.itemLabel {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  display: flex;
}

.itemLabel p {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  padding: 0;
  font-size: 24px;
}

.emptyIcon {
  margin: 0 auto;
  padding-bottom: 32px;
}

.subText {
  padding-top: 8px;
  font-size: 18px;
}
