.top {
  margin-bottom: 30px;
}

.search {
  background-color: #1f2027;
  padding: 0 20px;
  border-radius: 30px;
  box-shadow: 0 2px 15px 0 #000000;
  min-width: 340px;
  z-index: 2;
  position: absolute;
  margin-top: 30px;
}

.sectionTitle {
  margin: 0px 0px 15px 0px;
  padding: 0px 0px 0px 40px;
  font-family: Lato;
  font-size: 15px;
  font-weight: 900;
  color: #ffffff;
  opacity: 0.5;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 0px 0px 30px 0px;
  opacity: 10%;
  border-top: solid 2px #ffffff;
}

.playlistSection > .divider {
  margin-top: 15px;
}
.categoriesSection > .divider {
  margin-top: 30px;
}

.playlistName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 2px;
}

.icon {
  margin-right: 25px;
  opacity: 0.6;
}

.typeWrapper {
  display: flex;
}

.type {
  padding: 15px 10px 15px 40px;
  display: flex;
  margin-top: 2px;
  cursor: pointer;
  align-items: center;
  border-radius: 5px;
  border: 1px solid transparent;
  font-family: Lato;
  font-size: 20px;
  color: #ffffff;
  opacity: 0.66;
  flex: 1;
}

.type:hover > .icon,
.type:hover {
  background-color: #4c4c52;
  opacity: 1;
}

.selectedBar {
  background-color: #ffd000;
  width: 5px;
  border-radius: 5px;
  margin: 2px 5px 0px 0px;
}

.selectedType {
  padding-left: 30px;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.1);
}

.selectedType:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.count {
  margin-right: 30px;
  margin-left: auto;
  background-color: rgba(148, 148, 150, 0.5);
  border-radius: 50%;
  height: 35px;
  min-width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
}

.clearSearch {
  width: 13px;
}

.clearSearch:hover {
  cursor: pointer;
}

.clearSearch:active,
:hover,
:focus {
  outline: 0;
}

.searchInput {
  background-color: #1f2027;
  outline: 0;
  border: none;
  color: white;
  font-size: 15px;
  margin: 0 15px 0 15px;
  width: 80%;
  height: 60px;
  font-size: 20px;
  opacity: 0.5;
  color: #ffffff;
}

.block {
  margin-top: 20%;
}
