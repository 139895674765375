.base {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.preloadDevPreview {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1000;
  border: 5px solid #fd0b68;
  transform: scale(0.25) translate(150%, 150%);
}

.preload {
  visibility: hidden;
  z-index: -1;
}

.activeViewer {
  z-index: 1;
}
