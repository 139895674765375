.root {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(20, 20, 25, 0.9);
  flex: 1;
  z-index: 31;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
  width: 50%;
  margin: auto;
  text-align: center;
  height: 100%;
}

h1 {
  font-size: 42.5px;
  padding: 32px;
  font-weight: 700;
}

h2 {
  font-size: 27px;
  line-height: 1.5;
}

.button {
  padding: 1em;
  border: none;
  border-radius: 40px;
  width: 30%;
  min-width: 150px;
  font-family: Lato;
  font-size: 1.4em;
  background-color: #fecf00;
  color: #3d3d38;
  margin-bottom: 47px;
}

.button:hover {
  cursor: pointer;
}

.button:hover,
:active,
:focus {
  outline: none;
}
