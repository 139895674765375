/**
  * NB
  * - Only place global styles here. Styles for individual components should live in each component's folder.
  * - normalize.css is first imported via NPM in index.ts
  */

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

html,
body {
  background: transparent;
  color: #fff;
  font-family: Lato, Arial, Helvetica, sans-serif;
  overflow: hidden;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
